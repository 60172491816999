<template>
  <footer aria-labelledby="footer-heading" class="bg-darkbg-800">
    <div class="border-t-4 border-brand-500 bg-darkbg-500">
      <div class="flex h-16 items-center justify-between">
        <div class="flex flex-1 items-center justify-between gap-8 px-8">
          <!-- Logo -->
          <div>
            <router-link to="/">
              <cgn-organisation-logo class="h-10 w-auto" forcedark />
            </router-link>
          </div>
        </div>
        <!-- Red Color Block -->
        <div class="relative ml-4 flex h-full items-center bg-brand-600 px-2 text-sm uppercase sm:px-4 sm:text-lg">
          <div class="absolute left-0 -ml-8 h-full w-14 skew-x-[30deg] border-l-4 border-brand-700 bg-brand-600" />
          <div class="w-20" />
          <div class="absolute inset-0 flex items-center justify-center gap-3 pr-4">
            <a v-if="instagramLink" :href="instagramLink" class="block rounded-md bg-brand-700 p-2 text-on-brand hover:bg-brand-800">
              <i-fa:instagram />
            </a>
            <a v-if="facebookLink" :href="facebookLink" class="block rounded-md bg-brand-700 p-2 text-on-brand hover:bg-brand-800">
              <i-fa:facebook />
            </a>
          </div>
        </div>
      </div>
    </div>
    <h3 id="footer-heading" class="sr-only">
      Footer
    </h3>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div
        class="grid grid-cols-1 gap-8 py-8 md:grid-cols-2"
      >
        <router-link to="/" class="flex justify-center">
          <cgn-organisation-logo class="h-32 w-auto" forcedark />
        </router-link>
        <div class="flex flex-col gap-y-6">
          <!-- Footer links block -->
          <div class="flex flex-col gap-2">
            <div class="grid grid-flow-col grid-rows-3 gap-2 font-bold text-secondary-200">
              <div v-for="link in menus" :key="link.name" class="flex items-center">
                <router-link
                  :to="link.link"
                  class="capitalize hover:text-brand-500"
                >
                  {{ link.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between gap-4 pb-3 text-on-brand md:flex-row">
        <div class="flex items-center gap-1">
          <i-heroicons-outline:map-pin />
          {{ organisationAddress }}
        </div>
        <a class="flex items-center gap-1" :href="`tel:${organisationPhone}`">
          <i-heroicons-solid:phone />
          {{ organisationPhone }}
        </a>
      </div>
    </div>
    <div class="bg-darkbg-700 py-2 text-center">
      <p class="text-sm text-on-brand">
        &copy; Copyright {{ year }} Morgan Park Raceway
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'
import { CognitoGallery } from '~cognito/models/Cognito/Gallery'

const facebookLink = computed(() => {
  return usePagesStore().currentDomain.facebook_link
})
const instagramLink = computed(() => {
  return usePagesStore().currentDomain.instagram_link
})

const organisationPhone = computed(() => {
  return usePagesStore().currentDomain.organisation_phone
})
const organisationAddress = computed(() => {
  return usePagesStore().currentDomain.organisation_address
})

const year = ref(new Date().getFullYear())

const menus = ref<CognitoMenu[]>([])
async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('Footer Menu', '16x3', '1920')
}

const gallery = ref(new CognitoGallery())
async function loadGallery() {
  const data = await new CognitoGallery().find_one({
    url: 'footer-gallery',
    image_aspect: '1x1W',
    image_width: 300,
  })
  gallery.value = data
}

onMounted(() => {
  loadMenu()
  loadGallery()
})

onServerPrefetch(async () => {
  await loadMenu()
  await loadGallery()
})
</script>
